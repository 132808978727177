.module-navigation {
    &--clients {
        margin-top: 28px;
        position: relative;
        z-index: var(--z-clients);

        @include tablet() {
            margin-top: 48px;
        }

        ul {
            list-style: none;
            margin: 0;
            margin-top: 28px;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            column-gap: 48px;
            row-gap: 24px;

            @include tablet() {
                margin-top: 48px;
                row-gap: 28px;
            }
        }

        li {
            margin: 0;
            padding: 0;

            & > a,
            & > span,
            & > strong {
                font-weight: inherit;
                text-decoration: none;
                color: inherit;
            }
        }
    }
}

.module-navigation {
    &--socials {
        max-width: 640px;
        margin: 28px 0;

        @include tablet() {
            margin: 48px 0;
        }

        & > ul {
            list-style: none;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 48px;
            row-gap: 24px;
            margin: 0;
            padding: 0;

            @include tablet() {
                column-gap: 200px;
                row-gap: 28px;
            }

            & > li {
                margin: 0;
                padding: 0;
            }
        }

        li {
            margin: 0;
            padding: 0;

            & > a,
            & > span,
            & > strong {
                font-weight: inherit;
                text-decoration: none;
                color: inherit;
            }
        }
    }
}
