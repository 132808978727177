@mixin h1() {
    font-weight: 400;
    font-size: 50px;

    @include tablet() {
        font-size: 70px;
    }
}

@mixin h2() {
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;

    @include tablet() {
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
}

@mixin h3() {

}

@mixin h4() {

}

@mixin h5() {

}

@mixin h6() {

}

@mixin margin-type($type, $margin, $important: false) {
    & {
        @if $margin > 1 {
            @if $important {
                margin-#{$type}: #{$margin * 0.5}rem !important;

                @include tablet() {
                    margin-#{$type}: #{$margin * 0.75}rem !important;
                }

                @include desktop() {
                    margin-#{$type}: #{$margin}rem !important;
                }
            } @else {
                margin-#{$type}: #{$margin * 0.5}rem;

                @include tablet() {
                    margin-#{$type}: #{$margin * 0.75}rem;
                }

                @include desktop() {
                    margin-#{$type}: #{$margin}rem;
                }
            }
        } @else {
            @if $important {
                margin-#{$type}: #{$margin}rem !important;
            } @else {
                margin-#{$type}: #{$margin}rem;
            }
        }
    }
}

@mixin checker() {
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}

/* paragraph reset */
@mixin pr() {
    p {
        line-height: inherit;
    }

    p:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

@mixin button() {
    display: block;
    border: 2px solid white;
    border-radius: 20px;
    min-width: 150px;
    text-align: center;
    font-size: 20px;
    line-height: 1;
    padding: 0 20px;
    padding-top: 9px;
    padding-bottom: 7px;
    outline: 0;
    cursor: pointer;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    box-sizing: border-box;
    font-weight: 600;

    &:hover {
        text-decoration: none;
        color: white;
    }

    @include tablet() {
        font-size: 30px;
        min-width: 210px;
        border-radius: 25px;
    }
}
