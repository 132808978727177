.content-text {
    &--hero {
        @include pr();
        margin-top: max(-50px, calc(100vh - 10.8em - 138px - 10px));
        margin-bottom: 73px;
        line-height: 1;
        font-size: 50px;

        @include breakpoint(380px) {
            margin-top: max(-50px, calc(100vh - 9.9em - 138px - 10px));
        }

        @include breakpoint(425px) {
            margin-top: max(-50px, calc(100vh - 9em - 138px - 10px));
        }

        @include tablet() {
            margin-top: 0;
            max-width: 955px;
            font-size: 70px;
            line-height: 1;
            margin-bottom: 28px;
        }
    }

    &--what-we-do {
        margin-top: 180px;
        margin-bottom: 0;

        @include tablet() {
            margin-top: 255px;
        }
    }

    &--ready {
        font-size: vw(90px);
        margin-top: vw(40px);
        line-height: 0.9;
        @include pr();

        @include tablet() {
            font-size: vwmax(140px);
            margin-top: vwmax(100px);
            line-height: 0.8125;
            margin-right: calc(var(--outer-padding) * -1);
        }
    }
}
