#header {
    position: relative;
    z-index: var(--z-header);

    & > .inside {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5em;
        padding-left: var(--outer-padding);
        padding-right: var(--outer-padding);
        margin-top: 50px;
        margin-bottom: 74px;
        margin-left: auto;
        margin-right: auto;
        max-width: var(--max-width);
        box-sizing: border-box;

        @include tablet() {
            margin-top: 85px;
            margin-bottom: 126px;
        }
    }
}
