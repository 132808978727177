#container {
    position: relative;

    &:before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 40vh;
        background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        content: '';
        display: block;
    }

    &:after {
        content: '';
        display: table;
    }
}
