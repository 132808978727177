.logo-fragments {
    max-width: var(--max-width);
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: -138px;

    @include tablet() {
        top: vwmax(-228px);
    }

    &__inner {
        position: relative;
        width: 390px;
        left: 50%;
        margin-left: -195px;

        @include tablet() {
            width: var(--max-width);
            left: 50%;
            margin-left: calc(var(--max-width) * -0.5);
        }
    }
}

.logo-fragment {
    position: absolute;
    opacity: 0;
    transition: opacity 2s;

    img {
        max-width: none;

        &.transition {
            transition: transform 2s;
        }
    }

    &.show {
        opacity: 1;

        img {
            transform: none !important;
        }
    }

    &--1 {
        z-index: 1;
        left: 65px;
        top: -80px;

        img { width: 571px * 0.7; }

        @include tablet() {
            left: 770px;
            top: -170px;
    
            img { width: 571px; }
        }
    }

    &--2 {
        z-index: 2;
        left: -93px;
        top: 228px;

        img { width: 712px * 0.7; }

        @include tablet() {
            left: 753px;
            top: 135px;

            img { width: 712px; }
        }
    }

    &--3 {
        z-index: 2;
        left: -103px;
        top: 596px;

        img { width: 692px * 0.7; }

        @include tablet() {
            left: -86px;
            top: 341px;
    
            img { width: 692px; }
        }
    }

    &--4 {
        z-index: 1;
        left: 92px;
        top: 606px;

        img { width: 692px * 0.7; }

        @include tablet() {
            left: 306px;
            top: 347px;
    
            img { width: 692px; }
    
        }
    }
}
