/**
 * @font-face definitions
 */
@font-face {
    font-family: 'Bastardo';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('../../fonts/BastardoGrotesk-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Bastardo';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''), url('../../fonts/BastardoGrotesk-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Bastardo';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local(''), url('../../fonts/BastardoGrotesk-Semibold.woff2') format('woff2');
}
