#footer {
    background-color: black;
    position: relative;
    z-index: 1;
    overflow: hidden;

    & > .inside {
        padding: 25px var(--outer-padding);
        position: relative;
        box-sizing: border-box;
        max-width: var(--max-width);
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 2fr 1fr 2fr;
        align-items: center;
        row-gap: 46px;

        @include tablet() {
            padding: 29px var(--outer-padding);
        }
    }
}
