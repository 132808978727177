.content-list {
    &--technology-stack {
        max-width: 640px;
        margin: 28px 0;

        @include tablet() {
            margin: 48px 0;
        }

        & > ul, & > ol {
            list-style: none;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 48px;
            row-gap: 24px;
            margin: 0;
            padding: 0;

            @include tablet() {
                column-gap: 200px;
                row-gap: 28px;
            }

            & > li {
                margin: 0;
                padding: 0;
            }
        }
    }
}
