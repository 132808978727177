.module-navigation {
    &--footer {
        font-size: 20px;
        text-transform: uppercase;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            column-gap: 50px;
            row-gap: 1em;
        }

        li {
            margin: 0;
            padding: 0;

            & > a,
            & > span,
            & > strong {
                font-weight: inherit;
                text-decoration: none;
                color: inherit;
            }
        }
    }
}
