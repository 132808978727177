.content-block,
.content-text--what-we-do,
.content-text--who-we-are  {
    margin-top: 70px;
    margin-bottom: 70px;

    @include tablet() {
        margin-top: 150px;
        margin-bottom: 150px;
    }
}

.content-block--first {
    margin-top: 0;

    @include tablet() {
        margin-top: 0;
    }
}

.content-block--left,
.content-text--what-we-do {
    margin-right: 96px - $outer-padding-mobile;

    @include tablet() {
        margin-right: vwmax(230px);
    }
}

.content-block--right,
.content-text--who-we-are {
    margin-left: 50px;
    margin-right: 40px;

    @include tablet() {
        margin-right: 0;
        margin-left: vwmax(230px);
    }
}
