.content-image {
    &--signet-large {
        margin-top: vwmax(-256px);
        margin-bottom: vwmax(150px);

        img {
            margin: auto;
        }
    }
}
