@import "~normalize.css/normalize.css";
@import '~glightbox/dist/css/glightbox.min.css';

/* global settings */
@import './settings/_layout.scss';

/* functions and mixins */
@import './functions/_breakpoints.scss'; @import './functions/_grid.scss'; @import './functions/_mixins.scss'; @import './functions/_styles.scss';

/* base styles settings */
@import './base/_basic.scss'; @import './base/_fonts.scss'; @import './base/_typography.scss';

/* section styles */
@import './sections/_container.scss'; @import './sections/_footer.scss'; @import './sections/_header.scss'; @import './sections/_main.scss'; @import './sections/_wrapper.scss';

/* module styles */
@import './modules/_article.scss'; @import './modules/_navigation--clients.scss'; @import './modules/_navigation--footer.scss';

/* element styles */
@import './elements/_code.scss'; @import './elements/_gallery.scss'; @import './elements/_hyperlink.scss'; @import './elements/_image.scss'; @import './elements/_list.scss'; @import './elements/_text.scss';

/* styles of various components */
@import './components/_content-block.scss'; @import './components/_footer-copyright.scss'; @import './components/_footer-signet.scss'; @import './components/_lenis.scss'; @import './components/_logo-fragments.scss'; @import './components/_parallax.scss'; @import './components/_scroll-effect.scss'; @import './components/_tinymce.scss';
