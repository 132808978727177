#main > .inside > .module-article {
    padding: 0 var(--outer-padding);
    position: relative;
    z-index: var(--z-articles);

    &[data-controller="parallax"] {
        z-index: var(--z-parallax);
    }

    &--background {
        position: relative;
        padding-top: 1px;
        padding-bottom: 1px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: min(0px, (100vw - var(--max-width)) * -0.5);
            right: min(0px, (100vw - var(--max-width)) * -0.5);
            bottom: 0;
            height: 60vh;
            background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            content: '';
            display: block;
        }

        .bottom-background {
            height: vw(710px);
            position: absolute;
            left: min(0px, (100vw - var(--max-width)) * -0.5);
            right: min(0px, (100vw - var(--max-width)) * -0.5);
            bottom: 0;
            background-image: linear-gradient(90deg, var(--electric-indigo-80) 0%, var(--aquamarine-80) 100%);

            @include tablet() {
                height: vwmax(1500px);
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                display: block;
                background-image: linear-gradient(0deg, rgba(0,0,0,0.225) 0%, rgba(0,0,0,1) 100%);
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                display: block;
                background-image: url('../../images/background-top-desktop-crop.webp');
                background-position: center top;
                mix-blend-mode: difference;
                background-size: 250.5px auto;

                @include tablet() {
                    background-size: 501px auto;
                }
            }
        }

        & > .inside {
            position: relative;
            z-index: var(--z-first-content);
        }
    }

    &--ready {
        @include fwbg(black);

        & > .inside {
            &:before,
            &:after {
                content: '';
                display: table;
            }
        }
    }
}
