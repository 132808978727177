#wrapper {
    overflow: hidden;
    min-width: 280px;
    background-image: url('../../images/background-top-desktop-crop.webp');
    background-position: center top;
    background-size: 250.5px auto;

    @include tablet() {
        background-size: 501px auto;
    }
}
