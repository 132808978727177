.footer-copyright {
    font-size: 20px;
    white-space: nowrap;
    grid-column: auto / span 3;
    justify-self: center;
    
    @include tablet() {
        justify-self: flex-end;
        grid-column: auto / span 1;
    }
}
