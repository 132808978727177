.footer-signet {
    justify-self: center;
    grid-column: auto / span 2;
    justify-self: flex-end;

    @include tablet() {
        grid-column: auto / span 1;
        justify-self: center;
    }
}
