/**
 * Basic styling, basic font size, CSS variables etc.
 */
:root {
    --bastardo: 'Bastardo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --white: white;
    --black: black;
    --outer-padding: #{$outer-padding-mobile};
    --max-width: #{$max-width};

    --bp-menu: #{$menu};
    --bp-tablet: #{$tablet};

    --z-parallax: 8;
    --z-articles: 10;
    --z-header: 15;
    --z-first-content: 10;
    --z-clients: 12;

    --state-blue: #6f68f0;
    --aquamarine: #66FFD4;
    --aquamarine-80: #85ffdd;
    --electric-indigo: #4D2BFF;
    --electric-indigo-80: #7155ff;

    @include tablet() {
        --outer-padding: #{$outer-padding-desktop};
    }
}

html {
    scroll-behavior: smooth;
}

body, html {
    height: 100%;
    background-color: var(--black);
    color: var(--white);
}

body { 
    overflow: hidden;
    overflow-y: scroll;
}

figure {
    display: block;
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
}

main {
    display: block;
}

img {
    border: 0;
}

video {
    max-width: 100%;
    height: auto;
}

figure img {
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0;
}

figure figcaption {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

iframe {
    border:0;
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wow {
    visibility: hidden;
}

.inside {
    position:relative;
}

.ce_player video {
    width: 100%;
    height: auto;
}

/* default 16:9 aspect ratio */
.ce_youtube, .ce_vimeo {
    .video-wrapper,
    .video_container {
        position: relative;
        padding-bottom:56.25%;
        height:0;
    }

    .video-wrapper > *,
    .video_container > :not(.responsive):not(.caption),
    .video_container > .responsive > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: 6;
    }
}
