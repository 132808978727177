@use 'sass:math';

.content-gallery {
    margin: 1em 0;

    ul {
        list-style: none;
        padding: 0;
        display: grid;
        gap: 1em;
        grid-template-columns: 1fr;
    }

    @for $i from 1 through 12 {
        &--cols-#{$i} {
            ul {
                @include tablet() {
                    grid-template-columns: repeat(#{math.ceil(math.div($i, 2))}, 1fr);
                }

                @include desktop() {
                    grid-template-columns: repeat(#{$i}, 1fr);
                }
            }
        }
    }

    &--offset.content-gallery {
        ul {
            grid-template-columns: 1fr;
            row-gap: 70px;

            @include tablet() {
                row-gap: 150px;
            }

            li:nth-child(even) {
                margin-right: 96px - $outer-padding-mobile;

                @include tablet() {
                    margin-right: vwmax(230px);
                }
            }

            li:nth-child(odd) {
                margin-left: 50px;
                margin-right: 40px;
            
                @include tablet() {
                    margin-right: 0;
                    margin-left: vwmax(230px);
                }
            }
        }
    }
}
