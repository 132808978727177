.content-hyperlink {
    margin: 1em 0;

    &--button {
        display: flex;

        & > a {
            @include button();
        }

        .content-text--ready + & {
            margin-top: 50px;
            margin-bottom: 50px;

            @include tablet() {
                margin-top: 50px;
                margin-bottom: 84px;
            }
        }
    }
}
